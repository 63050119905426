import './App.css';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import Homepage from './pages/homepage/Homepage';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';

import useLanguageContext, { LanguageContext } from './context/useLanguageContext';
import useBgmContext, { BgmContext, BmgPlayer } from './context/useBgmContext';

function App() {

  const languageContext = useLanguageContext()
  const bgmContext = useBgmContext()
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Homepage />,
      
    },
    // {
    //   id: "homepage",
    //   path: "/p/:step",
    //   element: <Homepage />,
    //   loader: ({params}) => {
    //     return {step: params["step"]}
    //   } 
    // },
    {
      path: "privacyPolicy",
      element: <PrivacyPolicy />,
    },
  ]);

  

  return (
    <BgmContext.Provider value={{...bgmContext}}>
      <BmgPlayer/>
      <LanguageContext.Provider value={{...languageContext}}>
        <RouterProvider router={router} />
      </LanguageContext.Provider>
    </BgmContext.Provider>
  );
}

export default App;
