import { createContext, ReactNode, useEffect, useState } from "react"
import languageOptions from "../api/languageOptions"
import dicts from "../lans/dicts"


export interface LanguageContextDTO{
    translate: (label: string) => string
    language: string
    setLanguage: (language: string) => void
}
export const LanguageContext = createContext<LanguageContextDTO>({
    translate: function (label: string): string {
        throw new Error("Function not implemented.")
    },
    language: "",
    setLanguage: function (language: string): void {
        throw new Error("Function not implemented.")
    },
})

const get = () => {
    return localStorage.getItem("sys_lan")
}

const save = (language: string) => {
    localStorage.setItem("sys_lan", language)
}   


export default (): LanguageContextDTO => {

    const [language, setLanguage] = useState<string>(languageOptions[0].value)

    const translate = (label: string) => {
        let dict = dicts.getDict(language)
        if (!dict) {
            return label
        }
        return dict[label]??label
        //return label
    }

    useEffect(() => {
        let systemLanguage = navigator.language || (navigator as any).userLanguage;
        systemLanguage = normalize(systemLanguage)
        let _language = (get()??systemLanguage)??languageOptions[0].value
        save(_language)
        setLanguage(_language)
    }, [])

    const _setLanguage = (language: string) => {
        setLanguage(language)
        save(language)
    }

    useEffect(() => {
        document.title = translate("CATLY | Official Website")
        document.querySelector('meta[name="description"]')?.setAttribute('content', translate("description"))
        document.querySelector('meta[name="og:description"]')?.setAttribute('content', translate("description"))
    }, [language])

    return {
        translate,
        language,
        setLanguage: _setLanguage,
    }

}

const normalize = (rawLanguage: string) => {

    if(rawLanguage == "zh-CN") return "zh-CN"
    const splitToken = rawLanguage.indexOf("-")
    if(splitToken > 0){
        rawLanguage = rawLanguage.slice(0, splitToken)
    }
    if(languageOptions.find(it => it.value === rawLanguage)){
        return rawLanguage
    }else{
        return languageOptions[0]
    }
    

}