import styles from "./P1.module.scss"

// import video from "./assets/videos/video.mp4"
import videoEn from "./assets/videos/index_en.mp4"
import videoZh from "./assets/videos/index_zh.mp4"
import { CSSProperties, PropsWithChildren, useContext, useEffect, useRef, useState } from "react"
import { HomepageContext } from "../homepage/Homepage"
import { SwiperContext } from "../homepage/swiper/Swiper"
import { LanguageContext } from "../../context/useLanguageContext"
import regionOptions from "../../api/regionOptions"
import RegionDicts from "../../regions/dicts"

import UserApi, { RegisterRequest } from "../../api/api"
import Cursor from "../../components/anis/cursor/Cursor"

import Maidian, { MaidianEventType } from "../../api/maidian"

import {STEAM_URL, X_URL, IWATCH_URL} from "../../constants"

function isMobile (mobile: string) {
    return /^\d+$/.test(mobile)
  }

export default () => {

    const {
        maidianEventSend,
    } = Maidian

    // const {current: step} = useContext(SwiperContext)
    const {step} = useContext(HomepageContext)
    const {language} = useContext(LanguageContext)

    useEffect(() => {
        const video = document.querySelector("#p1Video") as HTMLVideoElement
        if(step == 0){
            console.log('视频开始播放')
            if (video.paused) {
                setTimeout(() => {
                    video.play()
                    .catch(err => {
                        console.log('p1播放失败')
                    })
                }, 1000);
            }
        }else{
            if (!video.paused) {
                video.pause()
            }
        }
    }, [step])

    useEffect(() => {
        const video = document.querySelector("#p1Video") as HTMLVideoElement
        // 禁用右键菜单
        video.addEventListener('contextmenu', function(event) {
            event.preventDefault(); // 阻止默认的右键菜单
        }, false);
    }, [])

    const iconClick = (type: string, url: string) => {
        maidianEventSend(MaidianEventType.ICON_CLICK, type)
        window.open(url, "_href")
    }

    return (
        <div className={styles['p1']}>
            <video 
            controls
            controlsList="nodownload nofullscreen noremoteplayback noplaybackrate"
            id="p1Video" 
            className={styles['p1__video']} 
            src={language === 'zh-CN'?videoZh:videoEn} 
            autoPlay={true} 
            loop={true} 
            muted={true} 
            preload="auto"
            ></video>
            <div className={[styles['p1__footer-wrapper'], true?styles['p1__footer-wrapper_show']:''].join(" ")}>
                <div className={styles['p1__footer']}>
                    <Zck/>
                    <div className={styles['p1__icons']}>
                        <div className={[styles['p1__icon'], styles['p1__icon_steam']].join(" ")} 
                            onClick={() => {iconClick('STEAM', STEAM_URL)}}
                        ></div>
                        <div className={[styles['p1__icon'], styles['p1__icon_x']].join(" ")}
                            onClick={() => {iconClick('X', X_URL)}}
                        ></div>
                        <div className={[styles['p1__icon'], styles['p1__icon_iwatch']].join(" ")}
                            onClick={() => {iconClick('IWATCH', IWATCH_URL)}}
                        ></div>
                        <div className={[styles['p1__icon'], styles['p1__icon_switch']].join(" ")}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const Zck = () => {

    const {
        register,
    } = UserApi

    const {
        maidianRegister,
    } = Maidian

    const {showHeader} = useContext(HomepageContext)
    const {translate: t, language} = useContext(LanguageContext)

    const [registered, setRegistered] = useState<boolean>(false)
    const [needRegion, setNeedRegion] = useState<boolean>(false)
    const [text, setText] = useState<string>("")
    const [region, setRegion] = useState<string>("00")

    const zckRef = useRef<HTMLDivElement>(null)
    const [inputStyle, setInputStyle] = useState<CSSProperties>({})
    const [countryInputStyle, setCountryInputStyle] = useState<CSSProperties>({})
    const [textInputStyle, setTextInputStyle] = useState<CSSProperties>({})
    const [btnRawWidth, setBtnRawWidth] = useState<number>(-1)
    const [btnStyle, setBtnStyle] = useState<CSSProperties>({})
    

    const inputHandle = (text: string) => {
        setText(text)
        setNeedRegion(isMobile(text))
    }

    const registerHandle = () => {
        if (!text) {
            //alert('手机或邮箱不能为空')
            return
        }
        setRegistered(true)

        //埋点上报
        maidianRegister()

        let phone
        let mail
        if (isMobile(text)) {
            phone = text
        } else {
            mail = text
        }

        register({region: region, phoneNumber: phone, email: mail })
        .then(res => {
            console.log('注册成功')
        }).catch(err => {
            console.error('注册失败', err)
        })
    }

    useEffect(() => {
        const btnRawWidth = zckRef.current?.querySelector("#registerBtn")?.getBoundingClientRect().width??0
        console.log("btnRawWidth: ", btnRawWidth)
        setBtnRawWidth(btnRawWidth)
    }, [])

    useEffect(() => {
        
        if(!needRegion){
            if(zckRef.current){
                // const rect = zckRef.current.getBoundingClientRect()
                const countryRect = zckRef.current.querySelector("#countryInput")?.getBoundingClientRect() as DOMRect
                const textInputRect = zckRef.current.querySelector("#textInput")?.getBoundingClientRect() as DOMRect
                // const tranX = textInputRect.left - countryRect.left
                setInputStyle({
                    transform: `translateX(-${countryRect.width}px)`
                })
                setCountryInputStyle({
                    opacity: 0
                })
                setTextInputStyle({
                    width: `calc(${countryRect.width}px + ${textInputRect.width}px)`
                })
            } 
        }else{
            setInputStyle({
                transform: `translateX(-${0}px)`
            })
            setCountryInputStyle({
                opacity: 1
            })
            setTextInputStyle({
                width: `60%`
            })
        }

    }, [needRegion])

    useEffect(() => {

        if(registered){
            setBtnStyle({
                maxWidth: "100%"
            })
        }else{
            let width = btnRawWidth
            if(btnRawWidth == -1){
                width = zckRef.current?.querySelector("#registerBtn")?.getBoundingClientRect().width??0
            }
            setBtnStyle({
                maxWidth: `${width}px`
            })
        }

    }, [registered])

    useEffect(() => {
        console.log(language === 'en')
    }, [])

    return (
        <div className={[styles['zck'], showHeader?styles['zck_show']:''].join(" ")} ref={zckRef} >
            <div className={[styles['zck__input'], registered?styles['zck__input_hidden']:''].join(" ")} style={inputStyle} >

                <RegionSelection onChange={setRegion}>
                    <div id="countryInput" className={[styles['zck__input__country-wrapper']].join(" ")} style={countryInputStyle}>
                        <div 
                        className={[
                            styles['zck__input__country'],
                            language === 'ru'?styles['input-ru']:'',
                            language === 'pt'?styles['input-pt']:'',
                            language === 'es'?styles['input-es']:'',
                            language === 'fr'?styles['input-fr']:'',
                        ].join(" ")}
                        >+{region}</div>
                    </div>
                </RegionSelection>
                
                <div id="textInput" className={[styles['zck__input__text-wrapper']].join(" ")} style={textInputStyle}>
                    <input 
                    className={[
                        styles['zck__input__text'],
                        language === 'ru'?styles['input-ru']:'',
                        language === 'pt'?styles['input-pt']:'',
                        language === 'es'?styles['input-es']:'',
                        language === 'fr'?styles['input-fr']:'',
                        language === 'en'?styles['input-en']:'',
                    ].join(" ")} 
                    placeholder={t('Email Address / Phone Number')} value={text} onChange={e => inputHandle(e.target.value)}></input>
                </div>
            </div>
            <div className={styles['zck__block']}></div>
            <div id="registerBtn"  className={styles['zck__btn-wrapper']} style={btnStyle}>
                <div className={[styles['zck__btn'], language === 'fr'?styles['btn-fr']:'', language === 'ru'?styles['btn-ru']:''].join(" ")} onClick={registerHandle}>
                    {registered?t('Registered Successfully'):t('Register Now')}
                </div>
            </div>
        </div>
        
    )

}

const RegionSelection = (props: PropsWithChildren<{onChange: (region: string) => void}>) => {

    const {translate: t, language, setLanguage} = useContext(LanguageContext)
    const [activate, setActivate] = useState<boolean>(false)
    const ref = useRef<HTMLDivElement>(null)
    const [positionStyle, setPositionStyle] = useState<CSSProperties>({})
    const [region, setRegion] = useState<string>("+00")
    const [selectRegion, setSelectRegion] = useState<string>("")
    const [regions, setRegions] = useState<any[]>(RegionDicts.getDict(language))

    const clickHandle = () => {
        const _activate = !activate
        setActivate(_activate)
    }

    const changeRegion = (region: string, name: string) => {
        setRegion(region)
        setSelectRegion(`${region}_${name}`)
        props.onChange(region)
    }

    useEffect(() => {
        const rect = ref.current?.getBoundingClientRect()
        rect && setPositionStyle({
            left: 0,
            bottom: `calc(80px * var(--scale))`
        })
    }, [])

    useEffect(() => {
        setRegions(RegionDicts.getDict(language))
    }, [language])

    return (
        <div className={styles["region-selection"]} onClick={clickHandle} ref={ref}  onWheelCapture={e => {
            e.stopPropagation();
            e.preventDefault()
        }}>
            <div className={styles['region-selection__parent']} >{props.children}</div>
            <div 
                className={[styles["region-selection__ul-wrapper"], !activate?styles["region-selection__ul-wrapper_hidden"]:''].join(" ")}
                style={positionStyle}
            >
                <div className={styles["region-selection__ul"]}>
                    {
                        regions.map((it, idx) => {
                            return (
                                <div key={idx} className={[styles['region-selection__li'], selectRegion===`${it[1]}_${it[0]}`?styles['region-selection__li_select']:''].join(" ")} onClick={() => changeRegion(it[1], it[0])}>
                                    <div className={styles['region-selection__li__phone']}>{it[1]}</div>
                                    <div className={styles['region-selection__li__country']}>{it[0]}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )

}

