import { createContext, useContext, useEffect, useState } from "react"
import Header from "../../components/layout/Header"
import P1 from "../p1/P1"
import Footer from "../footer/Footer"
import styles from "./Homepage.module.scss"
import pcCfgs from "../pcCfgs"
import { BgmContext, BmgPlayer } from "../../context/useBgmContext"
import { useLocation, useNavigate, useRouteLoaderData } from "react-router-dom"
import HomepageSwiper from "./swiper/HomepageSwiper"
import HomeSwiperItem from "./swiper/HomeSwiperItem"

import maidian, { MaidianEventType } from "../../api/maidian"

interface HomepageContextDTO{
  step: number
  white?: boolean //强制白底
  showHeader?: boolean //
  setStep: (step: number) => void
  incrStep: () => void
  decrStep: () => void
  checkNewUser: () => void
  registered: boolean
  setRegistered: (registry: boolean) => void
}
interface PageDTO{
  idx: number //从1开始
  height: number
}
export const HomepageContext = createContext<HomepageContextDTO>({
  step: 0, white: false, showHeader: false,
  setStep: function (step: number): void {
    throw new Error("Function not implemented.")
  },
  checkNewUser: function (): void {
    throw new Error("Function not implemented.")
  },
  registered: false,
  setRegistered: function (registry: boolean): void {
    throw new Error("Function not implemented.")
  },
  incrStep: function (): void {
    throw new Error("Function not implemented.")
  },
  decrStep: function (): void {
    throw new Error("Function not implemented.")
  }
})

const getRegistered = () => {
  return parseInt(localStorage.getItem("user_registered")??'0') > 0
}

const saveRegistered = () => {
  localStorage.setItem("user_registered", '1')
}

const getNewUser = () => {
  return parseInt(localStorage.getItem("user_initial")??'0') > 0
}

const saveNewUser = () => {
  localStorage.setItem("user_initial", '1')
}

const setHomeStep = (step: number) => {
  return sessionStorage.setItem("home_step", `${step}`)
}

const getHomeStep = () => {
  return parseInt(sessionStorage.getItem("home_step")??'0')
}

export default () => {
    
    let [step, setStep] = useState<number>(getHomeStep())
    let [showHeader, setShowHeader] = useState<boolean>()
    const {playing, play, pause, triggerPlay} = useContext(BgmContext)
    let [registered, setRegistered] = useState<boolean>(() => getRegistered())
    const loaderData = useRouteLoaderData("homepage") as any

    const {
      maidianOpen,
      maidianClose,
      maidianEventSend,
    } = maidian

    const navigate = useNavigate()

    const checkNewUser = () => {
      const initial = getNewUser()
      if(!initial){
        //初始化的用户，打开播放器
        saveNewUser()
      }
      triggerPlay()
    }

    const _setRegistered = () => {
      setRegistered(true)
      saveRegistered()
    }

    const incrStep = () => {
      setStep(current => {
        console.log("incrStep step: ", current)
        let step = current + 1
        step = Math.min(step, 1)
        setHomeStep(step)
        // navigate(`/p/${step}`)
        return step
      })
    }
    
    const decrStep = () => {
      setStep(current => {
        console.log("decrStep step: ", current)
        let step = current - 1
        step = Math.max(0, step)
        // navigate(`/p/${step}`)
        setHomeStep(step)
        return step
      })
    }

    useEffect(() => {
      setTimeout(() => {
        setShowHeader(true)
      }, pcCfgs.showHeaderDelay);
    }, [])


    // useEffect(() => {
    //   sessionStorage.setItem('step', step + "")
    // }, [step])

    useEffect(() => {
      // console.log("loaderData changed: ", loaderData)
      const _step = loaderData?.['step']??"0"
      let step = parseInt(_step)
      setStep(step)
  }, [loaderData])

  useEffect(() => {
    // const currentStp = getHomeStep()
    // console.log("catly homepage, step: ", currentStp)
    setStep(0)
  }, [])

  useEffect(() => {
    maidianOpen()
  }, [])

  // useEffect(() => {
  //   maidianEventSend(MaidianEventType.ENTER_PAGE, step)
  // }, [step])

  const handleBeforeUnload = (event: any) => {
    maidianClose()
  }

  useEffect(() => {
    // 添加事件监听器
    window.addEventListener('beforeunload', handleBeforeUnload);

    // 返回一个清除函数，在组件卸载时移除事件监听器
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

    return (
        <HomepageContext.Provider value={{step, showHeader, setStep, checkNewUser, registered, setRegistered: _setRegistered, incrStep, decrStep}}>
          <div className={styles['homepage']}>
            <Header className={[styles["homepage__header"], !showHeader?styles["homepage__header_hidden"]:""].join(" ")}/>
            <HomepageSwiper>
              {
                [
                  <P1/>,
                  // <Playbook/>,
                  // <Trailer/>,
                  // <P3/>,
                  // <P4/>,
                  // <P5/>,
                  // <P6/>,
                  // <P7/>,
                  // <P8/>,
                  <Footer/>
                ].map((p, index) => {
                  return <HomeSwiperItem idx={index} key={index}>{p}</HomeSwiperItem>
                })
              }
            </HomepageSwiper>
          </div>
        </HomepageContext.Provider>
    )
}


